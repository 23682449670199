import React, { useState } from "react"
import { Link } from "gatsby"
import "./header.styles.scss"
import ham from "../../images/hamburger.svg"
import logo from "../../images/logo.png"
import down from "../../images/down.svg"
import up from "../../images/up.svg"
import overlay from "../../images/overlay.svg"
import whitemap from "../../images/whitemap.svg"
const Header = props => {
  const [close, setClose] = useState(false)
  const [closeTwo, setCloseTwo] = useState(false)
  const [open, setOpen] = useState(false)
  const [openTwo, setOpenTwo] = useState(false)

  return (
    <div>
      <div id="myMenu" className={` overlays ${open ? "open_menu" : ""}`}>
        <div className="container">
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
          <Link
            to="#"
            className="closebtn"
            onClick={() => {
              setOpen(false)
            }}
          >
            &times;
          </Link>
          <div className="flex align-center wrap">
            <div className="overlay-content">
              <Link to="/" className={props.url === "/" ? "active" : ""}>
                Home
              </Link>
              <Link
                to="/about"
                className={props.url === "/about" ? "active" : ""}
              >
                About Us
              </Link>
              <Link
                to="#"
                onClick={() => {
                  setClose(!close)
                }}
                className={props.url === "/services" ? "active" : ""}
              >
                Services
                <img
                  src={close ? up : down}
                  className="pointer"
                  alt="down-up"
                  style={{ paddingLeft: "8px" }}
                />
              </Link>

              <ul className={close ? "show" : "hide"}>
                <Link to="/services/family-dentist">
                  <li>Family Dentistry</li>{" "}
                </Link>{" "}
                <Link to="/services/cosmetic-dentist">
                  <li>Cosmetic Dentistry</li>
                </Link>
                <Link to="/services/emergency-dentist">
                  <li>Emergency Dentistry</li>{" "}
                </Link>
                <Link to="/services/dental-implants">
                  <li>Dental Implants</li>{" "}
                </Link>
                <Link to="/services/veneers">
                  <li>Veneers </li>
                </Link>
              </ul>

              <Link
                className={props.url === "/blog" ? "active" : ""}
                to="/blog"
              >
                Blog
              </Link>

              <Link to="/contact">Contact Us</Link>
              <Link to="#contact" style={{ color: " #E1C42C" }}>
                Appointments
              </Link>
            </div>
            <div className="hides" style={{ width: "35%" }}>
              <img src={overlay} width="70%" alt="overlay" />
            </div>
            <div>
              <p
                style={{
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                <a
                  style={{ color: "white" }}
                  target="_blank"
                  href="https://www.google.com/maps/place/Dinkha+Dental/@32.7575638,-117.0165634,17z/data=!4m13!1m7!3m6!1s0x80d9573733c60daf:0x545eaa02da373b17!2s4323+Palm+Ave+C,+La+Mesa,+CA+91941!3b1!8m2!3d32.7575593!4d-117.0143747!3m4!1s0x80d957373350329f:0x2131bda9c043fe4f!8m2!3d32.7575921!4d-117.0143719"
                >
                  4323 Palm Ave, Ste C<br />
                  La Mesa, CA 91941
                </a>
              </p>{" "}
              <p>
                <a href="tel:(619) 462-9933" style={{ color: "white" }}>
                  (619) 462-9933
                </a>
              </p>
              <div className="flex add">
                <img className="pr-1" src={whitemap} alt="whitemap" />
                <p className="pr-1">
                  <a
                    style={{ color: "white" }}
                    target="_blank"
                    href="https://www.google.com/maps/place/Dinkha+Dental/@32.7575638,-117.0165634,17z/data=!4m13!1m7!3m6!1s0x80d9573733c60daf:0x545eaa02da373b17!2s4323+Palm+Ave+C,+La+Mesa,+CA+91941!3b1!8m2!3d32.7575593!4d-117.0143747!3m4!1s0x80d957373350329f:0x2131bda9c043fe4f!8m2!3d32.7575921!4d-117.0143719"
                  >
                    Get Direction
                  </a>
                </p>
                <a
                  href="mailto:info@dinkadental.com"
                  style={{ color: "#E1C42C", textDecoration: "underline" }}
                >
                  {" "}
                  <p style={{}}>info@dinkadental.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-container">
        <Link to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <span>
          <img
            src={ham}
            className="pointer"
            onClick={() => {
              setOpen(true)
            }}
            alt="hamburger"
          />
        </span>
      </div>
    </div>
  )
}

export default Header
