import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "./blog.Postsstyles.scss"
import blogpost from "../../../images/blogpost.png"
import Layout from "../../layout"

const BlogPosts = () => {
  const [state, setstate] = useState("")
  const data = useStaticQuery(graphql`
    query WrinklePageQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { page: { ne: true } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 450)
            id
            frontmatter {
              path
              blogtitle
              categories
              author
              thumbnails {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
              date(formatString: "MM.DD.YYYY")
            }
          }
        }
      }
    }
  `)
  const { edges: posts } = data.allMarkdownRemark

  var newPosts =
    state === ""
      ? posts
      : posts.filter(function (p) {
          // Return only the very first element found
          return p.node.frontmatter.categories.find(function (c) {
            return state.toLowerCase() === c.toLowerCase()
          })
        })

  console.log(newPosts)
  return (
    <>
      <Layout>
        <div className="section">
          <div className="sub-container">
            <div className="categories">
              <div className="flex category align-center">
                <p
                  className={state === "" ? "active pointer" : "pointer"}
                  onClick={() => setstate("")}
                >
                  All News
                </p>
                <p
                  className={state === "Dental" ? "active pointer" : "pointer"}
                  onClick={() => setstate("Dental")}
                >
                  Dental
                </p>
                {/*
                <p
                  className={state === "Medical" ? "active pointer" : "pointer"}
                  onClick={() => setstate("Medical")}
                >
                  Medical
                </p>
                <p
                  className={
                    state === "Insurance" ? "active pointer" : "pointer"
                  }
                  onClick={() => setstate("Insurance")}
                >
                  Insurance
                </p> */}
              </div>
            </div>
            {newPosts &&
              newPosts.map(({ node: post }, i) =>
                i === 0 ? (
                  <div className="blog-posts">
                    <div className="flex space-between align-center">
                      <div>
                        <Link to={`/blog/${post.frontmatter.path}`}>
                          {" "}
                          <img
                            src={`${post.frontmatter.thumbnails.childImageSharp.fluid.originalImg}`}
                            alt="blog"
                          />
                        </Link>
                      </div>
                      <div>
                        <h5 className="">{post.frontmatter.blogtitle}</h5>
                        <p>
                          <b>{post.frontmatter.author}</b>
                        </p>
                        <p>{post.excerpt}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            <div className="flex space-between wrap">
              {newPosts &&
                newPosts.map(
                  ({ node: post }, i) =>
                    i > 0 && (
                      <div className="blog-posts">
                        <Link to={`/blog/${post.frontmatter.path}`}>
                          <img
                            src={blogpost}
                            alt="blogpost"
                            className="blog-post-image"
                          />
                        </Link>
                        <div className="blog-excerpt">
                          <h5>{post.frontmatter.blogtitle}</h5>
                          <p>{post.frontmatter.author}</p>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BlogPosts
