import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogBanner from "../components/blog/banner/blogBanner.component"
import BlogPosts from "../components/blog/blog-posts/blogPosts.component"

const Services = () => {
  return (
    <>
      <Layout>
        <Seo title="Blog" />
        <BlogBanner />
        <BlogPosts />
      </Layout>
    </>
  )
}

export default Services
